import { Component, OnInit,OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/services/google-users.service';
import { utilities } from 'src/app/utilities/utilities';
import { count, Subscription } from 'rxjs';
import { JumpcloudUsersService } from 'src/app/services/jumpcloud-users.service';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { FormControl} from '@angular/forms';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { UserForwarding } from 'src/app/models/userForwarding.model';
import { listUser } from 'src/app/models/googleUser.model';
import {MenuItem} from 'primeng/api';  
import { Table } from 'primeng/table'
import jsPDF from 'jspdf'; 
import autoTable from 'jspdf-autotable'; 

@Component({
  selector: 'app-userlist',
  templateUrl: './user-list.page.html',
  styleUrls: ['./user-list.page.css']
})
export class UserlistComponent implements OnInit {
  usersList: any = null;
  useJumpcloud: boolean = false;
  selectedDirectory: string = "Google";
  searchControl = new FormControl();

  // googleUsersList: string[] = [];
  //jumpcloudUsersList;


  // declare subscription opjects for the subscriptions we are using, so we can unsubscribe on destroy.
  //googleUsersList_subscription: Subscription | undefined;
  jumpcloudUsersList_subscription: Subscription | undefined;
  useJumpcloud_subscription: Subscription | undefined;

  private suspendUsers: number = 0;
  private activeUsers: number = 0;
  menuitems: MenuItem[] = [];
  selectedUser: listUser = new listUser(); // returned by the right click as default.
  selectedUserData: listUser = new listUser();
  cols: any[];
  exportColumns: any[];
  seldirectoryName = "Google"; // future proofing for add to jumpcloud gorups.

  // resetPassword Modal
  selectedUserEmail: string;
  displayPasswordResetModal: boolean;
  
  // suspend / activate user Modal
  suspendMode: boolean;
  selectedUserFullName: string;
  displaySuspendUserModal: boolean;
  displayAddDelegateModal: boolean;
  visible: boolean = true;

  //add To Group:
  displayAddToGroupModal: boolean;
 
  constructor(
    private _usersService: UsersService,
    private _jumpcloud_users_service: JumpcloudUsersService,
    private _clientRepository: ClientRepository,
    private _userRepository: UserRepository,
    private util: utilities
    ) {

     }

  ngOnInit(): void {
    // this.googleUsersList_subscription = this._usersService.getGoogleUsers().subscribe(data => {
    //   if (data != null) {
    //     this.googleUsersList = data.body.users;
    //     this.util.debug(this.googleUsersList);
    //   }
    // });
    let client = this._clientRepository.getCurrentClient();
      

    this._userRepository.getGoogleListFromServer(client.client);

    /// do we need jumpcloud?

    if (client.usejc) {
        this._userRepository.getJumpcloudListFromServer(client.client);
    }

    this.cols = [
      { field: 'fullName', header: 'Name' },
      { field: 'primaryEmail', header: 'Email' },
      { field: 'orgDescription', header: 'Employee Type' },
      { field: 'orgTitle', header: 'Title' },
      { field: 'orgName', header: 'Organisation' },
      { field: 'creationTime', header: 'creationTime' }

    ];

    
    // this.setUseJC(this._clientRepository.getUseJumpCloud());
    

   
    this.searchControl.valueChanges
      .subscribe(value => {
        console.log(value);
        let filt = value.toUpperCase();
        let table = document.getElementById("userListTable");
        let tr = table!.getElementsByTagName("tr");

        // we need to loop through the rows then cells, so that we can then break and move to the next row if we find the search string.
        for (let i = 0; i < tr.length; i++) {
          let td = tr[i]!.getElementsByTagName("td");
          for (let i = 0; i < td.length; i++) {
            var txtValue = td[i].textContent || td[i].innerText;
            //console.log(txtValue)
            if (td[i]) {
              if (txtValue.toUpperCase().indexOf(filt) > -1) {
                td[i].closest('tr')!.style.display = "";
                //console.log("found: " + txtValue);
                break; // if we've found it on a cell in this rouw, break out of this loop so we don't check any more cells.
              } else {
                td[i].closest('tr')!.style.display = "none";
              }
            }
          }
        }
      });
  }

  ngOnChanges() {
    // calculate active and suspended users.
     this.suspendUsers = 0;
      this.activeUsers = 0;
      let num=this.newUsersList.length;
      
      for ( let i=0; i< num ; i ++) {
        if (this.newUsersList[i].suspended) {
          this.suspendUsers ++;
        } else {
          this.activeUsers ++;
        }
      }
      this.util.displayMessageToUser("There are " + this.activeUsers +  " active users and " + this.suspendUsers + " suspended users", "", false)
   
  }

  get newUsersList(){
 
    if (this.selectedDirectory == "JumpCloud") {
      return this.convertUserListYoTableFormat( this._userRepository.getJumpcloudUserList() );
    } else {
      return this._userRepository.getGoogleUsersListTableFormat();
    }

  }

  // get googleUsersList() {
  //  //return this._userRepository.getGoogleUserList();
  //  return this._userRepository.getGoogleUsersListTableFormat();
  // }

  // get newGoogleUsersList() {
  //   return this.convertUserListYoTableFormat( this._userRepository.getGoogleUserList() );
  //   // return this._userRepository.getGoogleUserList();
  // }

  // get newJumpCloudUsersList() {
   
  //   return this.convertUserListYoTableFormat( this._userRepository.getJumpcloudUserList() );
  // }

 convertUserListYoTableFormat (list) {
    let newList = [];
    let num= list.length;
    
    for (let i=0; i< num; i++) {
      let user = new listUser;
      user.primaryEmail = list[i].primaryEmail;
      user.thumbnailPhotoUrl = list[i].thumbnailPhotoUrl;
      user.fullName = list[i].name.fullName;
      if (list[i].organizations) { 
        user.orgDescription = list[i].organizations[0].description;
        user.orgName = list[i].organizations[0].name;
        user.orgTitle = list[i].organizations[0].title;
      }
      user.suspended = list[i].suspended;
      if ("creationTime" in  list[i]) {
        user.creationTime = list[i].creationTime 
        } else { 
            user.creationTime = "";
        }
      newList.push(user);
    }
    

    return newList;
  }

 
  get jumpcloudUsersList() {
    return this._userRepository.getJumpcloudUserList();
  }

  get clientName() {
    return this._clientRepository.getCurrentClientName();
  }


  clear(table: Table) {
    table.clear();
}

  

  // setUseJC(usejc) {
  //   console.log("useJumpcloud changed to: "+ usejc);
  //   this.useJumpcloud_subscription = this.useJumpcloud = usejc;
  //   // if we're using JC then setup the jumpcloud groups subscription.
  //   if (this.useJumpcloud) {
  //     this.jumpcloudUsersList_subscription = this._jumpcloud_users_service.getJumpCloudUsers().subscribe( data => {
  //       if(data ) {
  //         this.jumpcloudUsersList = data.body.users;
  //         this.util.debug("Jumpcloud Users");
  //         this.util.debug(data.body.users);
          
  //       }
  //     } ); 
  //   } else {
  //     /// make sure we reset to google if we are not using jumpcloud, and kill the groups subscrition.
  //     this.selectedDirectory = "Google";
  //   }
  // }

  onDirectoryChange(value: any) {
    this.util.debug("User Directory Changed to: "+ value);
    this.selectedDirectory = value;
    // if (value == 'Google') { 
    //   this.countUsers(this.googleUsersList);
    // } else if (value == 'JumpCloud') {
    //   this.countUsers(this.jumpcloudUsersList);
    // }
  }

  countUsers(usersList) {
    // console.log(usersList);
    
    let num = usersList.length;
    for ( let i=0; i< num ; i ++) {
      if (usersList[i].suspended) {
        this.suspendUsers ++;
      } else {
        this.activeUsers ++;
      }
    }
    this.util.displayMessageToUser(this.activeUsers +  " active users and " + this.suspendUsers + " suspended users", "", false)
  }

  // Right Click context menu. Have to do it like this as some of the options are dynamic.
  onLinkRightClicked(user, event) {
    console.log(user);
    //console.log(event);
    
    this.selectedUserEmail = user.primaryEmail;
    this.selectedUserFullName = user.fullName;
    this.selectedUserData = user;
    if(user.suspended) {
      this.menuitems = [{
        label: 'Activate User',
        icon: 'pi pi-ban',
        command: () => { 
        this.suspendMode = false; //set it to activate
        this.displaySuspendUserModal = true;
        }
      }]
    } else {
    
    this.menuitems = [
      {
        label: 'Reset Password',
        icon: 'pi pi-refresh',
        //set the username for the reset password component and tell it to display.
        command: () => { 
          this.displayPasswordResetModal = true;
        }
      },
      {
        label: 'Add Delegate',
        icon: 'pi pi-plus-circle',
        //set the username for the reset password component and tell it to display.
        command: () => { 
          this.displayAddDelegateModal = true;
        }
      },
      {
        label: 'Add to Group',
        icon: 'pi pi-user-plus',
        command: () => {
          this.displayAddToGroupModal = true;
        }
      },
      {
        label: 'Suspend User',
        icon: 'pi pi-ban',
        command: () => { 
    
          this.suspendMode = true; //set it to suspend
          this.displaySuspendUserModal = true;
          }
      }
      ]}
      
  }
  
  exportPdf() {
  
    const doc = new jsPDF('l', 'mm', 'a4'); 
  
    const head = [['Name', 'email', 'Organisation', 'Title', 'Suspended']]; 

    autoTable(doc, { 
        head: head, 
        body: this.toPdfFormat(), 
        didDrawCell: (data) => { }, 
    }); 
    doc.save('OurCLoud-User-Export.pdf'); 
} 

toPdfFormat() { 
   let suspended="";
    let data = []; 
    for (var i = 0; i < this.newUsersList.length; i++) { 
      suspended="";
      if (this.newUsersList[i].suspended) { suspended = "YES"; }

        data.push([ 
            this.newUsersList[i].fullName, 
            this.newUsersList[i].primaryEmail, 
            this.newUsersList[i].orgName,
            this.newUsersList[i].orgTitle, 
            suspended
        ]); 
    } 
    return data; 
} 

resetPasswordModal(event) {
  this.displayPasswordResetModal = false;
}


resetAddDelegateModal(event) {
  this.displayAddDelegateModal = false;
}

resetSuspendUserModal(event) {
  this.displaySuspendUserModal = false;
  // here we want to refresh the display !
  console.log("Suspend Mode = " + this.suspendMode );

  // we update the local users list, which will update the display :)  
  this.newUsersList.find((obj) => obj.primaryEmail === this.selectedUserEmail).suspended = this.suspendMode;


}

resetAddToGroupModal(e) {
  this.displayAddToGroupModal = false;
}



}



